.text-container{
    margin: 25px 75px;
}

.text-container > h3{
    text-align: center;
    margin-bottom: 30px;
}

.text-container > h4{
    margin: 10px 0px 10px 0px;
}

.text-container > ol{
    margin: 10px 20px;
}

@media (max-width: 768px) {
    .text-container{
        margin: 0px 35px 25px 25px;
    }

    .text-container > h3{
        margin-bottom: 15px;
    }
}