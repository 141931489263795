/* 
  footer
  =================
  Define el estilo para el contenedor principal del pie de página.

  Propiedades:
  - background-color: #F6FBFF; - Establece el color de fondo del pie de página a un tono claro.
  - color: #000E18; - Define el color del texto dentro del pie de página.
  - padding: 25px 0; - Añade espacio interno vertical (25px arriba y abajo) alrededor del contenido del pie de página.
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos hijos.
  - flex-wrap: wrap; - Permite que los elementos hijos se ajusten en múltiples líneas si es necesario.
  - justify-content: space-around; - Distribuye el espacio entre los elementos hijos de manera uniforme con espacio alrededor de cada uno.
  - border: none; - Elimina cualquier borde alrededor del contenedor del pie de página.
*/
footer {
  background-color: #F6FBFF;
    color: #000E18;
    padding: 25px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    border: none;
  }
  
  /* 
  .footer-section
  =================
  Define el estilo para las secciones dentro del pie de página.

  Propiedades:
  - flex: 1; - Permite que cada sección ocupe una parte igual del espacio disponible en el pie de página.
  - padding: 0 20px; - Añade espacio interno horizontal (20px a izquierda y derecha) alrededor del contenido de cada sección.
  - font-size: 14px; - Establece el tamaño de la fuente del texto dentro de las secciones del pie de página.
*/
  .footer-section {
    flex: 1;
    padding: 0 20px;
    font-size: 14px;
  }
  
  /* 
  .footer-logo
  =================
  Define el estilo para el contenedor del logo dentro del pie de página.

  Propiedades:
  - display: flex; - Utiliza el modelo de caja flexible para organizar el contenido del logo.
  - align-items: center; - Alinea verticalmente el contenido del logo en el centro.
*/
  .footer-logo {
    display: flex;
    align-items: center;
  }
  
  /* 
  .footer-logo img
  =================
  Define el estilo para la imagen del logo dentro del pie de página.

  Propiedades:
  - width: 20px; - Establece el ancho de la imagen del logo a 20px.
  - height: 20px; - Establece la altura de la imagen del logo a 20px.
  - margin: 5px; - Añade margen alrededor de la imagen del logo (5px en todos los lados).
*/
  .footer-logo img {
    width: 20px;
    height: 20px;
    margin: 5px;
  }
  
  /* 
  Media Queries
  =================
  Ajusta el diseño de las secciones del pie de página para pantallas más pequeñas.

  Reglas para pantallas con un ancho máximo de 768px:
  - .footer-section: 
    - flex-basis: 100%; - Hace que cada sección del pie de página ocupe el 100% del ancho del contenedor en pantallas pequeñas, forzando a cada sección a ocupar una línea completa.
*/
  @media (max-width: 768px) {
    .footer-section {
      flex-basis: 100%;
    }
  }
  