/* 
  .hero-banner-video 
  =================
  Define el contenedor principal para el hero banner,
  que es el área destacada en la parte superior de la página.

  Propiedades:
  - position: relative; - Establece el contexto para los elementos posicionados absolutamente dentro de este contenedor.
  - width: 100%; - Hace que el contenedor coupe el 100% del ancho de su elemento contendor padre.
  - height: 720px; - Define una altura fija para el contenedor. 
  - overflow: hidden; - Oculta cualquier contenido que se desborde fuera del contendor. 
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos hijos.
  - justify-content: center; - Centra los elementos hijos horizontalmente dentro del contenedor.
  - align-items: center; - Centra los elementos hijos verticalmente dentro del contenedor.
 */
.hero-banner-video {
  position: relative;
  width: 100%;
  height: 720px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* 
  #hero-video
  =================
  Define el estilo para un video de fondo que se ajusta para cubrir el área del hero banner.

  Propiedades:
  - position: absolute; - Posiciona el video de fondo de forma absoluta dentro del contenedor padre .hero-banner.
  - top: 0; - Alinea el video en la parte superior del contenedor padre.
  - left: 0; - Alinea el video en la parte izquierda del contenedor padre.
  - width: 100%; - Hace que el video ocupe el 100% del ancho del contenedor padre.
  - height: 100%; - Hace que el video ocupe el 100% de la altura del contenedor padre.
  - object-fit: cover; - Ajusta el video para que cubra el contenedor sin deformarse, recortando el video si es necesario.
  - z-index: -1; - Coloca el video detrás de otros elementos dentro del contenedor .hero-banner.
*/
#hero-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

/* 
  .hero-content
  =================
  Define el estilo para el contenido que se muestra sobre el video de fondo en el hero banner.

  Propiedades:
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos hijos.
  - flex-direction: column; - Organiza los elementos hijos en una columna vertical.
  - align-items: center; - Centra los elementos hijos horizontalmente.
  - text-align: center; - Centra el texto dentro del contenedor.
  - color: #ffffff; - Establece el color del texto a blanco para mejorar el contraste con el fondo del video.
*/
.hero-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #f6fbff;
}

/* 
  #button
  =================
  Define el estilo para un botón que puede ser mostrado en dispositivos con pantallas más pequeñas.

  Propiedades:
  - display: none; - Oculta el botón en pantallas grandes por defecto.
*/
#button {
  display: none;
}

/* 
  Media Queries
  =================
  Ajusta el diseño y la visibilidad de los elementos en pantallas más pequeñas.

  Reglas para pantallas con un ancho máximo de 768px:
  - .hero-banner: 
    - height: 360px; - Reduce la altura del banner a la mitad para adaptarse a pantallas más pequeñas.
  - #button: 
    - display: block; - Muestra el botón en pantallas más pequeñas, permitiendo su visibilidad en dispositivos móviles.
*/
@media (max-width: 768px) {
  .hero-banner {
    height: 360px;
  }

  #button {
    display: block;
  }
}
