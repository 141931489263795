/* 
  .card-container
  =================
  Define el estilo para el contenedor de tarjetas,
  organizando las tarjetas en un diseño flexible y adaptable.

  Propiedades:
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos hijos (tarjetas).
  - justify-content: space-around; - Distribuye los elementos hijos con espacio igual alrededor de cada tarjeta.
  - padding: 10px; - Añade espacio interno alrededor del contenido del contenedor.
  - margin-top: 20px; - Añade margen superior para separar el contenedor de tarjetas de los elementos anteriores.
  - flex-wrap: wrap; - Permite que las tarjetas se envuelvan en varias líneas si es necesario para adaptarse al ancho del contenedor.
*/

.card-container {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
}

/* 
  Media Queries
  =================
  Ajusta el diseño del contenedor de tarjetas en pantallas más pequeñas.

  Reglas para pantallas con un ancho máximo de 768px:
  - .card-container: 
    - flex-direction: column; - Cambia la dirección de los elementos hijos a una columna, apilando las tarjetas verticalmente.
    - align-items: center; - Centra las tarjetas horizontalmente dentro del contenedor.
    - height: auto; - Ajusta la altura del contenedor automáticamente para adaptarse al contenido.
    - margin-top: 0; - Elimina el margen superior para que el contenedor se alinee sin espacio adicional en pantallas pequeñas.
*/
@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    /* margin-top: 0; */
  }
}
