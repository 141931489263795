/* 
  .contact-container
  =================
  Define el estilo para el contenedor principal de la sección de contacto.

  Propiedades:
  - height: 400px; - Establece la altura del contenedor de contacto a 400px.
  - background-image: url("../../../public/contact-image.png"); - Define la imagen de fondo para el contenedor de contacto.
  - background-size: cover; - Asegura que la imagen de fondo cubra todo el contenedor, manteniendo su relación de aspecto.
  - background-position: center; - Centra la imagen de fondo dentro del contenedor.
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos hijos.
  - justify-content: space-around; - Distribuye el espacio entre los elementos hijos de manera uniforme, con espacio alrededor de cada uno.
  - align-items: center; - Alinea los elementos hijos verticalmente en el centro del contenedor.
  - padding: 10px; - Añade espacio interno alrededor del contenido del contenedor.
*/
.contact-container {
  height: 400px;
  background-image: url("../../../public/images/contact-image.png");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}

/* 
  .form-container
  =================
  Define el estilo para el contenedor del formulario dentro de la sección de contacto.

  Propiedades:
  - width: 50%; - Establece el ancho del contenedor del formulario al 50% del ancho del contenedor principal.
  - background-color: #fff6e9; - Define el color de fondo del contenedor del formulario.
  - padding: 30px 10px; - Añade espacio interno alrededor del contenido del formulario (30px arriba y abajo, 10px a los lados).
  - border-top-right-radius: 16px; - Redondea la esquina superior derecha del contenedor del formulario.
  - box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); - Aplica una sombra sutil alrededor del contenedor para darle un efecto de elevación.
  - border: none; - Elimina cualquier borde alrededor del contenedor del formulario.
*/
.form-container {
  width: 50%;
  background-color: #fff6e9;
  padding: 30px 10px;
  border-top-right-radius: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: none;
}

/* 
  .form-container form
  =================
  Define el estilo para el formulario dentro del contenedor del formulario.

  Propiedades:
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos del formulario.
  - flex-direction: column; - Organiza los elementos del formulario en una columna.
  - align-items: center; - Alinea los elementos del formulario verticalmente en el centro.
*/
.form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 
  #map
  =================
  Define el estilo para el mapa embebido en la sección de contacto.

  Propiedades:
  - height: 300px; 
    Establece la altura del mapa a 300px.
  - width: 45%; 
    Establece el ancho del mapa al 45% del contenedor principal.
  - border-radius: 16px; 
    Redondea las esquinas del mapa para un efecto visual más suave.
*/
#map {
  height: 300px;
  width: 45%;
  border-radius: 16px;
}

/* 
  Media Query para pantallas con un ancho máximo de 768px
  =================
  Ajusta los estilos para mejorar la visualización en dispositivos móviles.

  Cambios:
  - .contact-container: 
    - height: auto; 
      Ajusta la altura del contenedor de contacto a automática para adaptarse al contenido.
    - display: flex; 
      Mantiene el uso del modelo de caja flexible.
    - flex-direction: column; 
      Organiza los elementos del contenedor en una columna para dispositivos móviles.
    - padding: 20px 5px; 
      Ajusta el espacio interno para adaptarse a pantallas más pequeñas.
    - border: none; 
      Elimina el borde del contenedor (si lo hubiera).
  - .form-container: 
    - width: 90%; 
      Ajusta el ancho del contenedor del formulario al 90% del contenedor principal.
    - border-top-left-radius: 16px; 
      Redondea la esquina superior izquierda del contenedor del formulario para mayor consistencia en diseño móvil.
  - #map: 
    - margin-top: 20px; 
      Añade margen superior al mapa para separarlo del formulario en vistas móviles.
    - height: 300px; 
      Mantiene la altura del mapa.
    - width: 90%; 
      Ajusta el ancho del mapa al 90% del contenedor principal.
*/
@media (max-width: 768px) {
  .contact-container {
    height: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 5px;
    border: none;
  }

  .form-container {
    width: 90%;
    border-top-left-radius: 16px;
  }

  #map {
    margin-top: 20px;
    height: 300px;
    width: 90%;
  }
}
