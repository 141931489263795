.container-mvv {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 200px;
  gap: 25px;
  margin: 25px 75px;
}

.container-vision {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-mision {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container-valores {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 768px) {
  .container-mvv {
    grid-template-columns: repeat(1, 1fr);
  }
}
