.car.selected {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.car:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.container-simulator-car {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.container-cars {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px;
}

.car {
    width: 300px;
    height: 250px;
    margin-bottom: 20px;
}

.car img {
    width: 100%;
    height: auto;
}

.simulator-info-car {
    /* background-color: #ffffff; */
    /* border-top-right-radius: 16px; */
    width: 60%;
    height: 75%;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.column-one-car{
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

#pagoMensual{
    padding: 25px 0px 12px 0px;
}

.container-simulator-info-car{
    height: 150px;
    width: 90%;
    background-color: #f6fbff;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 25px 0 0 0;
    margin-bottom: 25px;
}

.simulator-content-info-car{
    background-color: #fff6e9;
    height: 100px;
    width: 95%;
    border-top-right-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 35px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .container-simulator-info-car {
      height: 150px;
      width: 100%;
      background-color: #f6fbff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      margin-bottom: 25px;
    }
  
    .simulator-content-info-car {
      height: 120px;
      width: 90%;
      max-width: 600px;
      /* display: flex; */
      /* flex-direction: column; */
      /* justify-content: center; */
      align-items: center;
    }

    .simulator-info-car{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .column-one-car{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 25px;
    }

    /* .one,
    .two,
    .three,
    .four {
      width: 100%;
      margin-bottom: 10px;
    } */
}