/* 
  .slideshow-container
  =================
  Define el estilo para el contenedor principal del slideshow.

  Propiedades:
  - max-width: 1000px; - Establece el ancho máximo del contenedor del slideshow a 1000px.
  - height: 400px; - Define la altura del contenedor del slideshow.
  - position: relative; - Permite posicionar los elementos hijos de manera absoluta dentro del contenedor.
  - margin: auto; - Centra el contenedor del slideshow horizontalmente en su contenedor padre.
*/
.slideshow-container {
  max-width: 1000px;
  height: 400px;
  position: relative;
  margin: auto;
}

.slideshow-container > h2 {
  text-align: center;
  margin-top: 25px;
}
/* 
  .mySlides
  =================
  Oculta las diapositivas por defecto.

  Propiedades:
  - display: none; - Oculta el elemento, haciendo que no sea visible en la página.
*/
.mySlides {
  display: none;
}

/* 
  .prev, .next
  =================
  Define el estilo para los botones de navegación previos y siguientes del slideshow.

  Propiedades comunes:
  - cursor: pointer; - Cambia el cursor a una mano cuando se pasa sobre el botón, indicando que es clickeable.
  - position: absolute; - Posiciona los botones de manera absoluta dentro del contenedor del slideshow.
  - top: 50%; - Ubica los botones en la mitad vertical del contenedor.
  - width: auto; - Ajusta el ancho del botón automáticamente.
  - margin-top: -22px; - Desplaza verticalmente el botón para centrarlo verticalmente en el contenedor.
  - padding: 16px; - Añade espacio interno alrededor del contenido del botón.
  - color: white; - Establece el color del texto del botón a blanco.
  - font-weight: bold; - Aplica negrita al texto del botón.
  - font-size: 18px; - Define el tamaño de la fuente del texto del botón.
  - transition: 0.6s ease; - Aplica una transición suave para los cambios en los estilos.
  - border-radius: 0 3px 3px 0; - Aplica bordes redondeados en los botones para un efecto estilizado.
  - user-select: none; - Previene la selección del texto dentro del botón.

  Propiedades específicas:
  - .next: 
    - right: 0; - Ubica el botón "next" en el borde derecho del contenedor.
    - border-radius: 3px 0 0 3px; - Redondea los bordes del botón "next" en las esquinas izquierda.
  - .prev:
    - border-radius: 3px 0 0 3px; - Redondea los bordes del botón "prev" en las esquinas derecha.
*/
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -22px;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}


/* 
  .dot
  =================
  Define el estilo para los indicadores (puntos) de la diapositiva actual.

  Propiedades:
  - cursor: pointer; - Cambia el cursor a una mano cuando se pasa sobre el indicador, indicando que es clickeable.
  - height: 15px; - Establece la altura del indicador.
  - width: 15px; - Establece el ancho del indicador.
  - margin: 0 2px; - Añade margen horizontal para separar los indicadores entre sí.
  - background-color: #bbb; - Define un color de fondo gris para los indicadores inactivos.
  - border-radius: 50%; - Aplica bordes redondeados para hacer que los indicadores sean circulares.
  - display: inline-block; - Hace que el indicador se muestre en línea con otros indicadores.
  - transition: background-color 0.6s ease; - Aplica una transición suave para los cambios en el color de fondo del indicador.
*/
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #FFF6E9;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  /* box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1); */
}


/* 
  .fade
  =================
  Aplica una animación de desvanecimiento a las diapositivas.

  Propiedades:
  - animation-name: fade; - Define el nombre de la animación que se debe aplicar.
  - animation-duration: 1.5s; - Establece la duración de la animación en 1.5 segundos.
*/
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}


/* 
  @keyframes fade
  =================
  Define la animación de desvanecimiento que se aplica a las diapositivas.

  - from { opacity: 0.4; } - Al inicio de la animación, la opacidad es 0.4.
  - to { opacity: 1; } - Al final de la animación, la opacidad es 1 (completamente visible).
*/
@keyframes fade {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}


/* 
  Media Queries
  =================
  Ajusta el diseño del contenedor del slideshow para pantallas más pequeñas.

  Reglas para pantallas con un ancho máximo de 768px:
  - .slideshow-container: 
    - height: auto; - Ajusta la altura del contenedor del slideshow automáticamente para adaptarse al contenido.
    - margin-bottom: 25px; - Añade margen inferior para separar el contenedor del slideshow de otros elementos.
*/
@media (max-width: 768px) {
  .slideshow-container {
    max-width: 1000px;
    height: auto;
    margin-bottom: 25px;
  }

  .slideshow-container > h2{
    font-size: 24px;
  }
}
