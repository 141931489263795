* {
  font-family: "Avenir", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1{
  font-size: 32px;
}

h2{
  font-size: 28px;
}

h3{
  font-size: 24px;
}

h4{
  font-size: 20px;
}

p{
  font-size: 16px;
}

a{
  font-size: 16px;
  text-decoration: none;
  color: 'inherit';
}

.btn {
  font-size: 16px;
  padding: 10px 50px;
  border-radius: 8px;
  font-weight: 600;
  color: #F6FBFF;
  background-color: #ffb200;
  cursor: pointer;
  border: 1px solid #ffb200;
}

.btn:hover {
  color: #ffb200;
  background-color: #F6FBFF;
  border: 1px solid #ffb200;
}

.btn-secondary{
  font-size: 16px;
  padding: 10px 50px;
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  border: 1px solid #ffb200;
  background-color: #FFFFFF;
  color: #ffb200;
}

.btn-secondary:hover {
  color: #ffb200;
  background-color: #fff6e9;
  border: 1px solid #ffb200;
}

@media (max-width: 768px) {
  .btn-secondary{
    padding: 10px 100px;
  }
}
