/* 
  .navbar
  =================
  Define el estilo para la barra de navegación principal, 
  que se posiciona en la parte superior de la página y se mantiene fija.

  Propiedades:
  - position: fixed; - Fija la barra de navegación en la parte superior de la página.
  - top: 0; - Alinea la barra de navegación en la parte superior del viewport.
  - left: 0; - Alinea la barra de navegación a la izquierda del viewport.
  - width: 100%; - Hace que la barra de navegación ocupe el 100% del ancho del viewport.
  - background-color: #F6FBFF; - Establece un color de fondo claro para la barra de navegación.
  - z-index: 1000; - Asegura que la barra de navegación se muestre sobre otros elementos de la página.
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos hijos.
  - justify-content: space-around; - Distribuye los elementos hijos de manera uniforme con espacio alrededor.
  - align-items: center; - Centra los elementos hijos verticalmente dentro de la barra de navegación.
  - padding: 10px 20px; - Añade espacio interno alrededor del contenido de la barra de navegación.
*/
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f6fbff;
  z-index: 1000;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 20px;
}

/* 
  .navbar-logo img
  =================
  Define el estilo para la imagen del logotipo dentro de la barra de navegación.

  Propiedades:
  - max-height: 40px; - Limita la altura máxima del logotipo para mantenerlo dentro del diseño de la barra de navegación.
*/
.navbar-logo img {
  max-height: 40px;
}

/* 
  .navbar-toggle
  =================
  Define el estilo para el botón de alternancia del menú en dispositivos móviles.

  Propiedades:
  - display: none; - Oculta el botón de alternancia por defecto en pantallas grandes.
*/
.navbar-toggle {
  display: none;
}

/* 
  .navbar-links
  =================
  Define el estilo para la lista de enlaces en la barra de navegación.

  Propiedades:
  - list-style-type: none; - Elimina los puntos de la lista.
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos de la lista.
  - gap: 20px; - Añade espacio entre los elementos de la lista.
*/
.navbar-links {
  list-style-type: none;
  display: flex;
  gap: 20px;
}

/* 
  .navbar-links li
  =================
  Define el estilo para los elementos de la lista dentro de los enlaces de la barra de navegación.

  Propiedades:
  - margin-right: 20px; - Añade margen derecho a los elementos de la lista.
*/
.navbar-links li {
  margin-right: 20px;
}

/* 
  .navbar-links p:hover
  =================
  Define el estilo para el estado de hover de los enlaces dentro de los elementos de la lista de la barra de navegación.

  Propiedades:
  - color: #ffb200; - Cambia el color del texto de los enlaces cuando el ratón pasa sobre ellos.
*/
.navbar-links p:hover {
  color: #ffb200;
}

/* 
  .mobile-menu
  =================
  Define el estilo para el menú móvil, que está oculto por defecto.

  Propiedades:
  - display: none; - Oculta el menú móvil por defecto.
*/
.mobile-menu {
  display: none;
}

/* 
  Media Queries
  =================
  Ajusta el diseño y la visibilidad de los elementos en pantallas más pequeñas.

  Reglas para pantallas con un ancho máximo de 768px:
  - .navbar: 
    - justify-content: space-between; - Cambia la distribución de los elementos para adaptarse a pantallas pequeñas.
  - .navbar-links: 
    - display: none; - Oculta los enlaces de navegación en pantallas pequeñas.
  - .navbar-links > li: 
    - margin: 20px; - Añade margen a los elementos de la lista en pantallas pequeñas.
  - .navbar-btn: 
    - display: none; - Oculta el botón de la barra de navegación en pantallas pequeñas.
  - .navbar-toggle: 
    - display: block; - Muestra el botón de alternancia para el menú en pantallas pequeñas.
    - cursor: pointer; - Cambia el cursor al pasar sobre el botón para indicar que es interactivo.
  - .toggle-icon: 
    - width: 30px; - Define el ancho del ícono de alternancia del menú.
    - height: 30px; - Define la altura del ícono de alternancia del menú.
    - background-color: #fff; - Establece un color de fondo blanco para el ícono de alternancia.
  - .mobile-menu-active: 
    - display: block; - Muestra el menú móvil cuando está activo.
    - position: fixed; - Fija el menú móvil en la pantalla.
    - top: 50px; - Coloca el menú móvil 50px desde la parte superior del viewport.
    - left: 0; - Alinea el menú móvil a la izquierda del viewport.
    - width: 100%; - Hace que el menú móvil ocupe el 100% del ancho del viewport.
    - height: auto; - Ajusta la altura del menú móvil automáticamente.
    - text-align: center; - Centra el texto dentro del menú móvil.
    - background-color: #fff; - Establece un color de fondo blanco para el menú móvil.
    - z-index: 100; - Asegura que el menú móvil se muestre sobre otros elementos de la página.
  - .mobile-links: 
    - padding-top: 20px; - Añade espacio interno en la parte superior de los enlaces móviles.
  - .mobile-links li: 
    - margin-bottom: 15px; - Añade margen inferior a los elementos de la lista en el menú móvil.
  - .mobile-links a:hover: 
    - color: #ffb200; - Cambia el color del texto de los enlaces móviles cuando el ratón pasa sobre ellos.
*/
@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .navbar-links {
    display: none;
  }

  .navbar-links > li {
    margin: 20px;
  }

  .navbar-btn {
    display: none;
  }

  .navbar-toggle {
    display: block;
    cursor: pointer;
  }

  .toggle-icon {
    width: 30px;
    height: 30px;
    background-color: #F6FBFF;
  }

  .mobile-menu-active {
    display: block;
    position: fixed;
    top: 50px;
    left: 0;
    width: 100%;
    height: auto;
    text-align: center;
    background-color: #F6FBFF;
    z-index: 100;
  }

  .mobile-links {
    padding-top: 20px;
  }

  .mobile-links li {
    margin-bottom: 15px;
  }

  .mobile-links a:hover {
    color: #ffb200;
  }
}
