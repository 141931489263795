/* 
  Estilo General para Campos de Entrada y Selectores
  =================
  Define el estilo para diferentes tipos de campos de entrada y selectores.

  Propiedades:
  - width: 90%; - Establece el ancho de los campos al 90% del contenedor padre.
  - padding: 8px; - Añade espacio interno alrededor del contenido de los campos.
  - margin-bottom: 15px; - Añade margen inferior para separar los campos entre sí.
  - border-radius: 8px; - Aplica bordes redondeados a los campos.
  - box-sizing: border-box; - Incluye el padding y el borde en el cálculo del ancho y la altura.
  - border: none; - Elimina el borde predeterminado de los campos.
  - box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); - Añade una sombra sutil alrededor de los campos para darles un efecto de elevación.
*/
input[type="text"],
input[type="email"],
input[type="number"],
input[type="tel"],
select {
  width: 90%;
  padding: 8px;
  margin-bottom: 15px;
  border-radius: 8px;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* 
  .container-simulator
  =================
  Define el estilo para el contenedor principal del simulador.

  Propiedades:
  - height: 450px; - Establece la altura del contenedor.
  - background-color: #F6FBFF; - Define un color de fondo claro para el contenedor.
  - display: flex; - Utiliza el modelo de caja flexible para organizar los elementos hijos.
  - justify-content: center; - Centra los elementos hijos horizontalmente.
  - align-content: center; - Centra los elementos hijos verticalmente.
  - padding: 25px 0 0 0; - Añade espacio interno en la parte superior del contenedor.
*/
.container-simulator {
  height: 450px;
  background-color: #f6fbff;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 25px 0 0 0;
  margin-bottom: 25px;
}

/* 
  .simulator-content
  =================
  Define el estilo para el área de contenido del simulador.

  Propiedades:
  - background-color: #fff6e9; - Establece un color de fondo suave para el área de contenido.
  - height: 400px; - Establece la altura del área de contenido.
  - width: 95%; - Establece el ancho del área de contenido al 95% del contenedor padre.
  - border-top-right-radius: 16px; - Aplica bordes redondeados en la esquina superior derecha del área de contenido.
  - display: flex; - Utiliza el modelo de caja flexible para organizar el contenido.
  - justify-content: center; - Centra el contenido horizontalmente.
  - align-items: center; - Centra el contenido verticalmente.
  - padding: 0 35px; - Añade espacio interno horizontal alrededor del contenido.
  - box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); - Añade una sombra sutil alrededor del área de contenido.
*/
.simulator-content {
  background-color: #fff6e9;
  height: 400px;
  width: 95%;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 35px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 
  .simulator-text
  =================
  Define el estilo para la sección de texto dentro del área de contenido del simulador.

  Propiedades:
  - width: 40%; - Establece el ancho de la sección de texto al 40% del área de contenido.
  - height: 100%; - Establece la altura de la sección de texto al 100% del área de contenido.
  - display: flex; - Utiliza el modelo de caja flexible para organizar el contenido de texto.
  - flex-direction: column; - Organiza los elementos de texto en una columna.
  - justify-content: center; - Centra el contenido verticalmente.
  - align-items: start; - Alinea los elementos de texto al inicio del contenedor horizontalmente.
*/
.simulator-text {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}

/* 
  #formularioPrestamo > input
  =================
  Define el estilo para los campos de entrada dentro del formulario de préstamo.

  Propiedades:
  - margin-top: 10px; - Añade margen superior para separar los campos de entrada entre sí.
*/
#formularioPrestamo > input {
  margin-top: 10px;
}

/* 
  #formularioPrestamo > p
  =================
  Define el estilo para los párrafos dentro del formulario de préstamo.

  Propiedades:
  - margin-bottom: 10px; - Añade margen inferior para separar los párrafos entre sí.
*/
#formularioPrestamo > p {
  margin-bottom: 10px;
}

/* 
  .simulator-info
  =================
  Define el estilo para el área de información dentro del simulador.

  Propiedades:
  - background-color: #Ffffff; - Establece un color de fondo blanco para el área de información.
  - border-top-right-radius: 16px; - Aplica bordes redondeados en la esquina superior derecha del área de información.
  - width: 60%; - Establece el ancho del área de información al 60% del área de contenido.
  - height: 75%; - Establece la altura del área de información al 75% del área de contenido.
  - box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); - Añade una sombra sutil alrededor del área de información.
*/
.simulator-info {
  background-color: #ffffff;
  border-top-right-radius: 16px;
  width: 60%;
  height: 75%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* 
  .column-one,
  .column-two,
  .column-three
  =================
  Define el estilo para las columnas dentro del área de información del simulador.

  Propiedades:
  - height: 33%; - Establece la altura de las columnas al 33% del área de información.
  - display: flex; - Utiliza el modelo de caja flexible para organizar el contenido de las columnas.
  - text-align: center; - Centra el texto dentro de las columnas.
  - justify-content: center; - Centra el contenido horizontalmente dentro de las columnas.
  - align-items: center; - Centra el contenido verticalmente dentro de las columnas.
*/
.column-one,
.column-two,
.column-three {
  height: 30%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* 
  .one,
  .two,
  .three,
  .four
  =================
  Define el estilo para los elementos dentro de las columnas.

  Propiedades:
  - width: 25%; - Establece el ancho de los elementos al 25% del ancho del contenedor de la columna.
*/
.one,
.two,
.three,
.four {
  width: 22%;
}

/* 
  Media Queries
  =================
  Ajusta el diseño del simulador en pantallas más pequeñas.

  Reglas para pantallas con un ancho máximo de 768px:
  - .container-simulator: 
    - height: 550px; - Aumenta la altura del contenedor para adaptarse a pantallas más grandes.
    - background-color: #F6FBFF; - Establece el color de fondo claro para el contenedor.
    - display: flex; - Utiliza el modelo de caja flexible para organizar el contenido.
    - justify-content: center; - Centra el contenido horizontalmente dentro del contenedor.
    - align-items: center; - Centra el contenido verticalmente dentro del contenedor.
  - .simulator-content: 
    - background-color: #fff6e9; - Establece un color de fondo suave para el área de contenido.
    - height: 530px; - Aumenta la altura del área de contenido para adaptarse a pantallas más grandes.
    - width: 95%; - Establece el ancho del área de contenido al 95% del contenedor padre.
    - max-width: 600px; - Limita el ancho máximo del área de contenido a 600px.
    - border-top-left-radius: 16px; - Aplica bordes redondeados en la esquina superior izquierda del área de contenido.
    - display: flex; - Utiliza el modelo de caja flexible para organizar el contenido.
    - flex-direction: column; - Organiza los elementos en una columna.
    - justify-content: center; - Centra el contenido verticalmente.
    - align-items: center; - Centra el contenido horizontalmente.
    - padding: 0 15px; - Añade espacio interno horizontal alrededor del contenido.
  - .simulator-text: 
    - width: 100%; - Establece el ancho de la sección de texto al 100% del área de contenido.
    - height: auto; - Ajusta la altura de la sección de texto automáticamente.
    - text-align: center; - Centra el texto dentro de la sección.
    - padding: 20px 0; - Añade espacio interno vertical alrededor del contenido de texto.
  - .simulator-text p: 
    - margin: 5px 0; - Añade margen vertical para separar los párrafos.
  - .simulator-info: 
    - background-color: #fff; - Establece el color de fondo blanco para el área de información.
    - border-top-left-radius: 16px; - Aplica bordes redondeados en la esquina superior izquierda del área de información.
    - width: 100%; - Establece el ancho del área de información al 100% del área de contenido.
    - height: auto; - Ajusta la altura del área de información automáticamente.
    - padding: 15px; - Añade espacio interno alrededor del contenido del área de información.
    - margin-bottom: 20px; - Añade margen inferior para separar el área de información de los elementos siguientes.
  - .column-one,
  .column-two,
  .column-three: 
    - height: auto; - Ajusta la altura de las columnas automáticamente.
    - padding: 10px 0; - Añade espacio interno vertical dentro de las columnas.
  - .one,
  .two,
  .three,
  .four: 
    - width: 100%; - Establece el ancho de los elementos al 100% del ancho del contenedor de la columna.
    - margin-bottom: 10px; - Añade margen inferior para separar los elementos entre sí.
    - font-size: 13px; - Reduce el tamaño de fuente para mejor adaptación a pantallas pequeñas.
*/
@media (max-width: 768px) {
  .container-simulator {
    height: 700px;
    background-color: #f6fbff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-bottom: 25px;
  }

  .simulator-content {
    background-color: #fff6e9;
    margin: 0;
    height: 630px;
    width: 95%;
    max-width: 600px;
    border-top-left-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
  }

  .simulator-text {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px 0;
  }

  .simulator-text p {
    margin: 5px 0;
  }

  .simulator-info {
    background-color: #fff;
    border-top-left-radius: 16px;
    width: 100%;
    height: auto;
    padding: 15px;
    margin-bottom: 20px;
  }

  .column-one,
  .column-two,
  .column-three {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
  }

  .one,
  .two,
  .three,
  .four {
    width: 100%;
    margin-bottom: 10px;
    font-size: 13px;
  }
}
