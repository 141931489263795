/* 
  .card
  =================
  Define el estilo para cada tarjeta dentro del contenedor de tarjetas.

  Propiedades:
  - width: calc(30% - 20px); - Establece el ancho de la tarjeta como el 30% del contenedor menos 20px para espacio entre tarjetas.
  - max-width: 30%; - Limita el ancho máximo de la tarjeta al 30% del contenedor para mantener el diseño.
  - margin-bottom: 20px; - Añade margen inferior para separar cada tarjeta verticalmente.
  - border-top-left-radius: 16px; - Aplica un radio de borde en la esquina superior izquierda para bordes redondeados.
  - border-top-right-radius: 16px; - Aplica un radio de borde en la esquina superior derecha para bordes redondeados.
  - background-color: #F6FBFF; - Establece un color de fondo claro para la tarjeta.
  - color: #000E18; - Establece el color del texto dentro de la tarjeta.
  - text-align: center; - Centra el texto dentro de la tarjeta.
  - box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); - Añade una sombra sutil alrededor de la tarjeta para dar un efecto de elevación.
  - transition: transform 0.3s ease; - Añade una transición suave para la transformación de la tarjeta al pasar el ratón sobre ella.
*/
.card {
  width: calc(30% - 20px);
  max-width: 30%;
  margin-bottom: 20px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  background-color: #FFFFFF;
  color: #000E18;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease;
}

/* 
  .card:hover
  =================
  Define el estilo para la tarjeta cuando el ratón pasa sobre ella.

  Propiedades:
  - transform: translateY(-5px); - Eleva la tarjeta 5px hacia arriba cuando el ratón pasa sobre ella.
  - box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); - Aumenta la sombra alrededor de la tarjeta para un efecto de profundidad adicional.
*/
.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* 
  .card img
  =================
  Define el estilo para las imágenes dentro de cada tarjeta.

  Propiedades:
  - width: 100%; - Establece el ancho de la imagen al 100% del contenedor de la tarjeta.
  - height: auto; - Ajusta la altura de la imagen automáticamente para mantener la proporción.
  - display: block; - Asegura que la imagen se muestre como un bloque para evitar espacio en línea debajo de la imagen.
  - border-top-left-radius: 16px; - Aplica un radio de borde en la esquina superior izquierda de la imagen para que coincida con el diseño de la tarjeta.
  - border-top-right-radius: 16px; - Aplica un radio de borde en la esquina superior derecha de la imagen para que coincida con el diseño de la tarjeta.
*/
.card  img {
  width: 100%;
  height: auto;
  display: block;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

/* 
  .card-info
  =================
  Define el estilo para el contenedor de información dentro de la tarjeta.

  Propiedades:
  - padding: 20px; - Añade espacio interno alrededor del contenido de la información dentro de la tarjeta.
*/
.card-info {
  padding: 20px;
}

.card-info > p {
  font-size: 14px;
}

/* 
  Media Queries
  =================
  Ajusta el diseño de las tarjetas en pantallas más pequeñas.

  Reglas para pantallas con un ancho máximo de 768px:
  - .card: 
    - width: 100%; - Establece el ancho de la tarjeta al 100% del contenedor para pantallas más pequeñas.
    - max-width: 90%; - Limita el ancho máximo de la tarjeta al 90% del contenedor para mantener márgenes y espaciado.
*/
@media (max-width: 768px) {
  .card {
    width: 100%;
    max-width: 90%;
  }
}
